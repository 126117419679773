import React, { useState, useEffect } from "react";
import iso from "iso-3166-1";
// import API from "../../api";
import { Redirect, NavLink, useHistory } from "react-router-dom";
// import ModernDatepicker from "react-modern-datepicker";
import moment from "moment";

import AlertsTop from "../reusables/AlertsTop";
import Loader from "../reusables/Loader";
import Swal from "sweetalert2";
import * as api from "../../api";

import { useDispatch, useSelector } from "react-redux";

import { createUser } from "../../actions/users";

const Signup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userCreateResponse = useSelector((state) => state.user.response);
  // const { authState } = useOktaAuth()
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));

  const [loading, setLoading] = useState(false);
  const [registerSuccessful, setRegisterSuccessful] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [errorIndicator, setErrorIndicator] = useState();
  const [countries, setCountries] = useState([]);
  const [practices, setPractices] = useState([]);
  const [signupError, setsignupError] = useState(null);

  //user information state variables
  let lovedOnesFormData = [];
  const [numOfLovedOnes, setNumOfLovedOnes] = useState(0);
  //const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userInfo, setUserInfo] = useState({
    // login: null,
    firstName: "",
    middleName: "",
    lastName: "",
    nickName: "",
    dateOfBirth: "",
    gender: "male",
    address: "",
    zipCode: "",
    countryCode: "AFG",
    practice: "",
    email: "",
    password: "",
    clientApp: ["HAPIPARTNERS"],
    // title: "Mr.",
    // ifLovedOne: false,
    // lovedOnes: [],
  });
  const [birthday, setBirthday] = useState("");

  useEffect(() => {
    if (birthday && birthday !== "") {
      const birthDate = moment(birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
      setUserInfo({
        ...userInfo,
        dateOfBirth: birthDate,
      });
    }
  }, [birthday]);

  useEffect(() => {
    if (iso.all().length > 0) {
      setCountries(iso.all());
    }
  }, [iso]);

  useEffect(() => {
    if (practices) {
      setUserInfo({
        ...userInfo,
        practice: practices[0]?._id,
      });
    }
  }, [practices]);

  //this runs when their is an error creating a user;
  // useEffect(() => {
  //   console.log("useeffect create error", userCreateResponse);

  //   if (userCreateResponse) {
  //     if (userCreateResponse.status === 200) {
  //       console.log("user creation was successful");
  //       setRegisterSuccessful(true);
  //     } else {
  //       console.log("ther is an error creating a user");
  //       setErrorMessage(`There was an error creating the user`);
  //     }
  //   }
  // }, [userCreateResponse]);

  const validateForm = () => {
    let isValid = false;

    if (
      userInfo.firstName === "" ||
      userInfo.middleName === "" ||
      userInfo.lastName === "" ||
      userInfo.nickName === "" ||
      userInfo.dateOfBirth === "" ||
      userInfo.address === "" ||
      userInfo.zipCode === "" ||
      userInfo.countryCode === "" ||
      userInfo.email === "" ||
      userInfo.password === ""
      // userInfo.nickname === null ||
      // userInfo.lovedOnes === null ||
    ) {
      if (userInfo.firstName === "") {
        setErrorIndicator("firstName");
      } else if (userInfo.middleName === "") {
        setErrorIndicator("middleName");
      } else if (userInfo.lastName === "") {
        setErrorIndicator("lastName");
      } else if (userInfo.nickName === "") {
        setErrorIndicator("nickName");
      } else if (userInfo.dateOfBirth === "") {
        setErrorIndicator("dateOfBirth");
      } else if (userInfo.address === "") {
        setErrorIndicator("address");
      } else if (userInfo.zipCode === "") {
        setErrorIndicator("zipCode");
      } else if (userInfo.countryCode === "") {
        setErrorIndicator("countryCode");
      } else if (userInfo.email === "") {
        setErrorIndicator("email");
      } else if (userInfo.password === "") {
        setErrorIndicator("password");
      }
      // } else if (userInfo.middlename === null) {
      //   setErrorIndicator("middle name");
      setErrorMessage("Please fill in all the fields.");
      isValid = false;
    } else if (userInfo.password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setErrorIndicator("password");
      isValid = false;
    } else if (userInfo.password.length < 8) {
      setErrorMessage("Password needs to be 8 characters long.");
      setErrorIndicator("password");
      isValid = false;
    } else {
      isValid = true;
    }

    //this is the love ones field validation
    if (userInfo.ifLovedOne === true) {
      for (let i = 0; i < numOfLovedOnes; i++) {
        let randomWords = userInfo.lovedOnes[i].randomWords.split(",");
        if (
          userInfo.lovedOnes[i].hasOwnProperty("lastName") === false ||
          userInfo.lovedOnes[i].lastName === ""
        ) {
          console.log("u need to enter a firstname" + i);
          setErrorMessage(`You need to enter a last name for loved one.`);
          setErrorIndicator(`lastname${i}`);
          isValid = false;
        } else if (randomWords.length !== 3) {
          console.log(randomWords);
          setErrorMessage(`Loved ones random words should be 3 words.`);
          setErrorIndicator(`randomwords${i}`);
          isValid = false;
        }
      }
    }

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (validateForm()) {
        console.log("the form is valid");
        const data = await api.signUpUser(userInfo);
        // let postData = { ...userInfo, login: userInfo.email };
        // dispatch(createUser(postData)).then(() => {
        //   setErrorMessage("");
        //   setLoading(false);
        // });
        Swal.fire({
          html: "Successfully signed up! An email will be sent to you once the admin approved your request!",
          icon: "success",
          title: "Success",
          confirmButtonColor: "#833f84",
        });
        setLoading(false);
        history.push("/login");
      } else {
        console.log("the form is invalid");
        // setsignupError("The form is invalid");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setsignupError(error.response.data.errors);
    }
  };

  // error for adding consent
  useEffect(() => {
    if (signupError) {
      // console.log(signupError);
      const errors = signupError.map((err) => {
        return err.message + "\r\n";
      });
      Swal.fire({
        title: "Error!",
        html: errors,
        icon: "error",
        confirmButtonColor: "#833f84",
      });
    }
  }, [signupError]);

  const getPractice = async () => {
    setLoading(true);
    try {
      const data = await api.getPractices();
      const practice = data.data.data;
      setPractices(practice);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPractice();
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;

    setUserInfo({ ...userInfo, [name]: value });
  };

  // const handleLovedOnesChange = (event, i) => {
  //   const { value, name } = event.target;

  //   setUserInfo({ ...userInfo, ifLovedOne: true });

  //   let userLoveOnes = userInfo.lovedOnes;
  //   userLoveOnes[i] = { ...userLoveOnes[i], [name]: value };

  //   setUserInfo({ ...userInfo, lovedOnes: userLoveOnes });
  // };

  // const lovedOnesForm = () => {
  //   if (numOfLovedOnes > 5) {
  //     setNumOfLovedOnes(5);
  //   }

  //   for (let i = 0; i < numOfLovedOnes; i++) {
  //     lovedOnesFormData.push(
  //       <div className="row">
  //         <div className="col-sm-12 col-md-4">
  //           <label htmlFor="lastname">Last Name</label>
  //           <input
  //             onChange={(e) => {
  //               handleLovedOnesChange(e, i);
  //             }}
  //             className={`form-control ${
  //               errorIndicator === `lastname${i}` ? "border border-danger" : ""
  //             }`}
  //             type="text"
  //             name="lastName"
  //             required
  //           />
  //         </div>
  //         <div className="col-sm-12 col-md-4">
  //           <label htmlFor="firstname">3 Random words</label>
  //           <input
  //             onChange={(e) => {
  //               handleLovedOnesChange(e, i);
  //             }}
  //             className={`form-control ${
  //               errorIndicator === `randomwords${i}`
  //                 ? "border border-danger"
  //                 : ""
  //             }`}
  //             type="text"
  //             name="randomWords"
  //             required
  //             placeholder="Separated by comma"
  //           />
  //         </div>
  //         <div className="col-sm-12 col-md-4">
  //           <label htmlFor="middlename">Date of Birth</label>
  //           <input
  //             onChange={(e) => {
  //               handleLovedOnesChange(e, i);
  //             }}
  //             className="form-control"
  //             type="date"
  //             name="dateOfBirth"
  //             required
  //           />
  //         </div>
  //       </div>
  //     );
  //   }

  //   return lovedOnesFormData;
  // };

  // if (!authState) return null;

  if (loading) return <Loader />;

  // return authState.isAuthenticated ? (
  //   <Redirect to={{ pathname: "/" }} />
  // ) : (
  return (
    <div className="container mt-3" style={{ margin: "150px auto" }}>
      {errorMessage && (
        <AlertsTop text={errorMessage} bsClass="alert alert-danger" />
      )}
      {registerSuccessful && (
        <AlertsTop
          text="Registration was successful you may now login."
          bsClass="alert alert-success"
        />
      )}
      <div className="container p-3 mt-2 bg-light border shadow-sm">
        {successMessage ? (
          <p className="text-center text-success">{successMessage}</p>
        ) : null}
        <form>
          <div className="form-group">
            <p className="text-center lead mb-4">Sign up</p>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label htmlFor="firstName">First Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "firstName" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="firstName"
                  value={userInfo.firstName.toLowerCase() ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="middleName">Middle Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "middleName"
                      ? "border border-danger"
                      : ""
                  }`}
                  type="text"
                  name="middleName"
                  value={userInfo.middleName.toLowerCase() ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="lastname">Last Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "lastname" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="lastName"
                  value={userInfo.lastName.toLowerCase() ?? ""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label htmlFor="nickName">Nick Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "nickName" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="nickName"
                  value={userInfo.nickName.toLowerCase() ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4" style={{ zIndex: 1 }}>
                <label htmlFor="dateOfBirth">Date Of Birth</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "dateOfBirth"
                      ? "border border-danger"
                      : ""
                  }`}
                  type="date"
                  name="dateOfBirth"
                  value={userInfo.dateOfBirth ?? ""}
                />
                {/* <div style={{ border: "1px solid #ced4da" }}>
                  <ModernDatepicker
                    className={`form-control ${
                      errorIndicator === "dateOfBirth"
                        ? "border border-danger"
                        : ""
                    }`}
                    format="DD/MM/YYYY"
                    name="dateOfBirth"
                    date={birthday}
                    onChange={(date) => setBirthday(date)}
                    primaryColor="#f36917"
                    placeholder="dd/mm/yyyy"
                    // showBorder
                  />
                </div> */}
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="gender">Gender</label>
                <select
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "gender" ? "border border-danger" : ""
                  }`}
                  id="gender"
                  name="gender"
                  value={userInfo.gender ?? "male"}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-sm-12 col-md-2">
                <label htmlFor="title">Title</label>
                <select
                  onChange={handleChange}
                  className="form-control"
                  id="title"
                  name="title"
                >
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-5">
                <label htmlFor="nickname">Nick Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "nick name" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="nickName"
                />
              </div>
              <div className="col-sm-12 col-md-5">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "zip code" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="zipCode"
                />
              </div>
            </div> */}

            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label htmlFor="address">Address</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "address" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="address"
                  value={userInfo.address ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "zipCode" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="zipCode"
                  value={userInfo.zipCode ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="countryCode">Country</label>
                <select
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "countryCode"
                      ? "border border-danger"
                      : ""
                  }`}
                  id="gender"
                  name="countryCode"
                  value={userInfo.countryCode ?? "AFG"}
                >
                  <option disabled>SELECT COUNTRY</option>
                  {countries &&
                    countries.map((data, index) => (
                      <option value={data.alpha3} key={index}>
                        {data.country}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label htmlFor="practice">Practice</label>
                <select
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "practice" ? "border border-danger" : ""
                  }`}
                  id="practice"
                  name="practice"
                  value={userInfo.practice ?? ""}
                >
                  <option disabled>SELECT PRACTICE</option>
                  {practices &&
                    practices.map((data) => (
                      <option value={data._id}>{data.label}</option>
                    ))}
                </select>
              </div>
              <div className="col-sm-12 col-md-6">
                <label htmlFor="email">Email</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "email" ? "border border-danger" : ""
                  }`}
                  type="email"
                  name="email"
                  value={userInfo.email.toLowerCase() ?? ""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label htmlFor="password">Password</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "password" ? "border border-danger" : ""
                  }`}
                  type="password"
                  name="password"
                  value={userInfo.password ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`form-control ${
                    errorIndicator === "password"
                      ? "border border-danger"
                      : ""
                  }`}
                  type="password"
                  name="confirmPassword"
                />
              </div>
            </div>

            {/* <div className="border-top my-3"></div> */}

            {/* <p className="text-center lead">Add a loved one</p> */}

            {/* loved ones */}

            {/* {lovedOnesForm()} */}

            {/* <button
              disabled={numOfLovedOnes === 5}
              className={`btn orange-1 mt-2 ${
                numOfLovedOnes === 5 ? "text-dark" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setNumOfLovedOnes(numOfLovedOnes + 1);
                setUserInfo({ ...userInfo, ifLovedOne: true });
              }}
            >
              <i className="fas fa-plus-circle mx-2"></i>
              Add loved one
            </button> */}

            <button
              className="btn btn-primary mt-3 w-100 py-3"
              onClick={handleSubmit}
            >
              Sign Up
            </button>
            <NavLink
              className="text-center d-block mt-2"
              to="/login"
              onClick={() => {
                setErrorMessage("");
                setErrorIndicator("");
                setSuccessMessage("");
              }}
            >
              Go back to login
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
